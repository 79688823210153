@layer base {
  .main {
  display: flex;
  position: sticky;
  z-index: calc(var(--z-index-website-menu) + 1);
  top: 0;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--sk-color-black);
  color: var(--sk-color-white);
  gap: var(--sk-space-16);
}

.mainWrapper {
  padding: var(--sk-space-16);
}

.main a {
  color: currentcolor;
  text-underline-color: currentcolor;
}

.main p {
  margin: 0;
  padding: 0;
}

}