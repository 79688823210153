:root {
  /*
    Useful in case an element should be below another one that is:
    - positioned in CSS (e.g. position != `static`)
    - AND before in the order of appearance in the HTML.
  */
  --z-down-in-the-current-stacking-context: -1;

  /*
    Useful in case an element should be reset in a stack context.
  */
  --z-reset-in-the-current-stacking-context: 0;

  /*
    Useful in case an element should be above another one that is:
    - positioned in CSS (e.g. position != `static`)
    - AND after in the order of appearance in the HTML.
  */
  --z-up-in-the-current-stacking-context: 1;

  /*
    UI-Kit Cards have often a z-index of 1:
    - JobOfferCard on its "Save" button
    - InsightCard on its link::after element
    - etc.

    Also, UI-Kit buttons have a z-index of 1.

    We define custom properties to be able to place an element above them.
  */
  --z-above-ui-kit-cards: 2;
  --z-above-ui-kit-buttons: 2;
  --z-above-ui-kit-video-player: 2;

  /*
    Order of main stacked elements:
    page content < website menus < modals <  dev tools < notifications

    Note that these elements (except the page content) are also creating a stacking context by having:
    - a position value `absolute` or `relative` and a `z-index` value other than `auto`.
    - OR a `position` value `fixed` or `sticky`.
  */

  --z-index-website-menu: 4; /* 4 to be above the highest raw value in the code base, which is 3 */

  --z-index-modal: calc(var(--z-index-website-menu) + 1);

  --z-index-algolia-dev-tools: calc(var(--z-index-modal) + 1);
  --z-index-dev-tools: calc(var(--z-index-algolia-dev-tools) + 1);

  --z-index-notifications-panel: calc(var(--z-index-dev-tools) + 1);
}
